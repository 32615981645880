<template>
    <div class="contaner_w">
        <new-header :config="headerConfig"></new-header>
        <div class="content_box" @click="contentBtn(1)">
            <img src="../../assets/Group_42442@2x.png" />
        </div>
        <div class="content_box" @click="contentBtn(2)">
            <img src="../../assets/Group_42443@2x.png" />
        </div>
    </div>
</template>
<script type>
import newHeader from "@/components/newHeader";
export default {
    components: {
        newHeader,
    },
    data() {
        return {
            headerConfig: {
                show: true,
                title: "选择合同类型",
                showKeufu: true,
            },
        };
    },

    created() {
        document.title = "选择合同类型";
    },
    methods: {
        contentBtn(index) {
            if (index == 2) {
                this.$router.push("./electronic")
            }else{
                localStorage.removeItem('audit_info')
                this.$router.push("./Information")
            }

        }
    }
};
</script>
<style scoped>
.contaner_w {
    background: #F9F9FB;
    width: 100%;
    min-height: 100vh;
}

.content_box {
    width: 690px;
    height: 200px;
    margin: 50px auto 30px;

}

.content_box img {
    width: 100%;
    height: 100%;
}
</style>
